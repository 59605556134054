import React from 'react';
import { PostSummary } from '../../types/shared.types';
import { ArticleCard } from '../news/ArticleCard';
import { SectionHeader } from '../sections/SectionHeader';

type Props = {
    news: Array<PostSummary>;
};

export const LatestNews: React.FC<Props> = ({ news }) => {
    return (
        <div className="latest-news">
            <SectionHeader sectionTitle="Latest News" sectionCTALabel="All News" link="/news" />
            <div className="news-cards">
                {news.map((article: PostSummary, index: number) => (
                    <ArticleCard className="on-home" key={`latest-news-card-${index}`} article={article} />
                ))}
            </div>
        </div>
    );
};
