import { useNeedsOnboardingInfo } from '../../../client/hooks';
import { Footer } from '../../footer/Footer';
import { FixedProfileIncomplete } from '../../sections/FixedProfileIncomplete';
import { NavigationBar } from '../navigation/NavigationBar';
import { HeadMeta } from './HeadMeta';

type Props = {};

export const HomeLayout: React.FC<Props> = ({ children }) => {
    const { needs } = useNeedsOnboardingInfo();
    return (
        <>
            <HeadMeta />
            <NavigationBar isTransparentAtTop={false} />
            {children}
            <Footer />
            <FixedProfileIncomplete show={needs} />
        </>
    );
};
