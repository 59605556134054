import React from 'react';

type Props = {
    className?: string;
};

export const ScoutingReportIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? ` ${className}` : ''}`} width="110" height="65" viewBox="0 0 110 65">
            <g id="grad-cap" transform="translate(-524 -1308)">
                <g id="Path_10" data-name="Path 10" className="cls-2" transform="translate(524 1308)">
                    <path
                        className="cls-5"
                        d="M8,0h94a8,8,0,0,1,8,8V57a8,8,0,0,1-8,8H8a8,8,0,0,1-8-8V8A8,8,0,0,1,8,0Z"
                    />
                    <path
                        className="cls-6"
                        d="M 8 4 C 5.794403076171875 4 4 5.794399261474609 4 8 L 4 57 C 4 59.20560073852539 5.794403076171875 61 8 61 L 102 61 C 104.2055969238281 61 106 59.20560073852539 106 57 L 106 8 C 106 5.794399261474609 104.2055969238281 4 102 4 L 8 4 M 8 0 L 102 0 C 106.4182739257812 0 110 3.58172607421875 110 8 L 110 57 C 110 61.41827392578125 106.4182739257812 65 102 65 L 8 65 C 3.581710815429688 65 0 61.41827392578125 0 57 L 0 8 C 0 3.58172607421875 3.581710815429688 0 8 0 Z"
                    />
                </g>
                <circle
                    id="Ellipse_2"
                    data-name="Ellipse 2"
                    className="cls-3"
                    cx="4"
                    cy="4"
                    r="4"
                    transform="translate(612 1321)"
                />
                <circle
                    id="Ellipse_3"
                    data-name="Ellipse 3"
                    className="cls-3"
                    cx="4"
                    cy="4"
                    r="4"
                    transform="translate(612 1337)"
                />
                <circle
                    id="Ellipse_4"
                    data-name="Ellipse 4"
                    className="cls-3"
                    cx="4"
                    cy="4"
                    r="4"
                    transform="translate(612 1353)"
                />
                <g id="Group_237" data-name="Group 237" transform="translate(538 1321.79)">
                    <g id="Group_236" data-name="Group 236" className="cls-4">
                        <path
                            id="Path_154"
                            data-name="Path 154"
                            className="cls-1"
                            d="M58.022,10.988,43.208,1.056l-.029.011A3.988,3.988,0,0,0,36.9,2.2H28.728A3.99,3.99,0,0,0,22.45,1.062l-.026-.006L7.534,10.832l.073.006a14.7,14.7,0,1,0,21.678,14.7H36.1A14.7,14.7,0,1,0,58.022,10.988M14.7,34.008A10.293,10.293,0,1,1,25,23.715,10.294,10.294,0,0,1,14.7,34.008m18.108-11.4a3.637,3.637,0,1,1,3.638-3.637,3.637,3.637,0,0,1-3.638,3.637m17.865,11.4A10.293,10.293,0,1,1,60.97,23.715,10.293,10.293,0,0,1,50.677,34.008"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};
