import React from 'react';
import { JourneySlogan } from '../journey/JourneySlogan';
import { PlatformMessaging } from '../svg/home/PlatformMessaging';
import { ProfileView } from '../svg/home/ProfileView';
import { ScoutingReportIcon } from '../svg/ScoutingReportIcon';
import { FeatureCard } from './home-card/FeatureCard';

type Props = {};

export const HomeJourney: React.FC<Props> = ({}) => {
    return (
        <div className="home-journey-new">
            <div className="container slim">
                <JourneySlogan hasSubText={false} hasCTA={false} />
                <div className="dashboard-features">
                    <FeatureCard
                        icon={<PlatformMessaging className="plat-message" />}
                        label={'Platform Messaging'}
                        description={'Instantly reach out using esports recruiting messages.'}
                    />
                    <FeatureCard
                        icon={<ProfileView className="profile-view" />}
                        label={'Profile Views'}
                        description={'Be notified when someone views your profile.'}
                    />
                    <FeatureCard
                        icon={<ScoutingReportIcon className="scouting-report" />}
                        label={'Scouting Report'}
                        description={'Be listed on recruiting reports for colleges to see.'}
                    />
                </div>
            </div>
        </div>
    );
};
