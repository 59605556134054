import { gql } from '@urql/core';
import React from 'react';
import { Espin_Event, UpcomingEventsFragment } from '../../generated/spin-graphql';
import { EventCard } from '../events/card/EventCard';
import { SectionHeader } from '../sections/SectionHeader';

type Props = {
    events: UpcomingEventsFragment[];
};

gql`
    fragment UpcomingEvents on espin_event {
        ...EventCard
    }
`;

export const UpcomingEvents: React.FC<Props> = ({ events }) => {
    return (
        <div className="upcoming-events">
            <SectionHeader
                sectionTitle={'Upcoming Recruiting Events'}
                sectionCTALabel={'All Recruiting Events'}
                link="/events"
            />
            <div className="event-cards">
                {events.map((event: Espin_Event, index: number) => (
                    <EventCard key={`upcoming-event-card-${index}`} event={event} />
                ))}
            </div>
        </div>
    );
};
