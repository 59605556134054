import { gql } from '@urql/core';
import Link from 'next/link';
import React from 'react';
import { EventCardFragment } from '../../../generated/spin-graphql';
import { EventCardContent } from './EventCardContent';
import { EventCardHeader } from './EventCardHeader';

type Props = {
    className?: string;
    event: EventCardFragment;
    isRegistered?: boolean;
};

gql`
    fragment EventCard on espin_event {
        id
        slug
        ...EventCardHeader
        ...EventCardContent
    }
`;

export const EventCard: React.FC<Props> = ({ className, event, isRegistered }) => {
    return (
        <Link href={`/events/${event.slug}`}>
            <a className={`event-card${className ? ` ${className}` : ''}`}>
                <EventCardHeader event={event} />
                <EventCardContent event={event} isRegistered={isRegistered} />
            </a>
        </Link>
    );
};
