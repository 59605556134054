import React from 'react';
import { useAppState } from '../../client/providers/AppProvider';
import { SpinAppModals, useModals } from '../common/SpinModalProvider';

type Props = {
    hasSubText: boolean;
    hasCTA: boolean;
};

export const JourneySlogan: React.FC<Props> = ({ hasSubText, hasCTA }) => {
    const { openModal } = useModals();
    const { user } = useAppState();
    return (
        <div className="journey-slogan">
            <div className="container">
                <h2 className="title">
                    {'The platform to connect players to'}
                    <br />
                    {'colleges to gain esports scholarships.'}
                </h2>
                {hasSubText && <p className="subtitle">Start your journey to a scholarship in esports today.</p>}
                {hasCTA && !!!user && (
                    <button
                        onClick={() => {
                            openModal(SpinAppModals.SIGNUP_SELECT_ACCT_TYPE);
                        }}
                        className="button large-button green-button"
                    >
                        Sign Up
                    </button>
                )}
            </div>
        </div>
    );
};
