import { gql } from '@urql/core';
import React from 'react';
import { buildFileUrlOrNull } from '../../../client/images.utils';
import { BackgroundImage } from '../../backgrounds/FadedBackgroundImage';
import { EventCardHeaderFragment } from '../../../generated/spin-graphql';
import { PictureWithFallback } from '../../sections/PictureWithFallback';

gql`
    fragment EventCardHeader on espin_event {
        name
        icon {
            ...FormFile
        }
        logo {
            ...FormFile
        }
    }
`;

type Props = {
    event: EventCardHeaderFragment;
};
export const EventCardHeader: React.FC<Props> = ({ event }) => {
    const logoUrl = buildFileUrlOrNull(event.logo);

    return (
        <div className="event-card-header">
            <BackgroundImage imageSrc={logoUrl} altText={`${event.name} logo`} hasOverlay={true} />
            <div className="event-icon">
                <PictureWithFallback icon={event.icon} width={120} height={120} alt={`${event.name}} icon`} />
            </div>
        </div>
    );
};
