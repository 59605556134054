import React from 'react';
import { useAppState } from '../../client/providers/AppProvider';

type Props = {
    ctaOnClick: () => void;
};

export const HomeHeader: React.FC<Props> = ({ ctaOnClick }) => {
    const { user } = useAppState();
    return (
        <header className="hero-header">
            <div className="container slim">
                <h1 className={`header-title${!!user ? ' standalone' : ''}`}>{`Esports Scholarships`}</h1>
                <h2 className="header-support">WHERE PLAYERS AND COLLEGES CONNECT</h2>
                {!!!user && (
                    <button onClick={ctaOnClick} className="button large-button green-button">
                        START TODAY
                    </button>
                )}
            </div>
        </header>
    );
};
