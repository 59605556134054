import { gql } from '@urql/core';
import { format } from 'date-fns';
import React from 'react';
import { EventCardContentFragment } from '../../../generated/spin-graphql';
import { isWithinRegistrationRange } from '../../../shared/shared.utils';
import { GameIcon } from '../../icons/GameIcon';

gql`
    fragment EventCardContent on espin_event {
        name
        slug
        type
        prize
        event_start_date
        event_end_date
        register_end_date
        register_start_date
        event_games {
            game {
                game_definition
                name
            }
        }
    }
`;

type Props = {
    event: EventCardContentFragment;
    isRegistered?: boolean;
};

export const EventCardContent: React.FC<Props> = ({ event, isRegistered }) => {
    const formatter = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    });
    const prizePool: string | null = event.prize ? formatter.format(event.prize) : null;

    const startDate = new Date(event.event_start_date);
    const endDate = new Date(event.event_end_date);

    let eventDescription = `${event.type.toLocaleUpperCase()} - ${format(startDate, 'LLLL d')}`;

    if (endDate.getMonth() === startDate.getMonth()) {
        eventDescription += `-${format(endDate, 'd, y')}`;
    } else {
        eventDescription += ` - ${format(endDate, 'LLLL d, y')}`;
    }

    let button = (
        <button className="button blue-button medium-button">
            {isWithinRegistrationRange(event.register_start_date, event.register_end_date) ? 'Register' : 'Details'}
        </button>
    );

    if (isRegistered) {
        button = (
            <button className="button light-blue-button medium-button disabled" disabled={true}>
                Registered
            </button>
        );
    }

    return (
        <div className="event-card-content">
            <h2 className="event-title">{event.name}</h2>
            <p className="event-description">{eventDescription}</p>
            <div className="games-container">
                {event.event_games.map((g) => (
                    <GameIcon
                        key={`game-${g.game.game_definition}`}
                        game={g.game.game_definition}
                        gameName={g.game.name}
                    />
                ))}
            </div>
            <div className="bottom-container">
                {prizePool && (
                    <>
                        <p className="prize-pool">PRIZE POOL</p>
                        <p className="prize-value">{prizePool}</p>
                    </>
                )}
                {button}
            </div>
        </div>
    );
};
