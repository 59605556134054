import React from 'react';

interface Props {
    className?: string;
}

export const ClosedQuoteIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? ` ${className}` : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path d="M14.6,11.5H11v-10h9v9.7l-3.6,7.3H11L14.6,11.5z" />
            <path d="M3.6,11.5H0v-10h9v9.7l-3.6,7.3H0L3.6,11.5z" />
        </svg>
    );
};
