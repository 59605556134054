import Link from 'next/link';
import React from 'react';
import { RightArrow } from '../svg/RightArrow';

type Props = {
    sectionTitle: string;
    sectionCTALabel: string;
    link: string;
};

export const SectionHeader: React.FC<Props> = ({ sectionTitle, sectionCTALabel, link }) => {
    return (
        <div className="section-header">
            <h2 className="section-title">{sectionTitle}</h2>
            <Link href={link}>
                <a className="section-cta">
                    {sectionCTALabel}
                    <RightArrow />
                </a>
            </Link>
        </div>
    );
};
