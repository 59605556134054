import React from 'react';
import { ClosedQuoteIcon } from '../svg/ClosedQuoteIcon';
import { OpenQuoteIcon } from '../svg/OpenQuoteIcon';

type Props = {
    quote: string;
    name: string;
    twitter?: string;
    subText: string;
};

export const Testimonial: React.FC<Props> = ({ quote, name, twitter, subText }) => {
    return (
        <div className="testimonial-card">
            <div className="open-quote">
                <OpenQuoteIcon />
            </div>
            <div className="closed-quote">
                <ClosedQuoteIcon />
            </div>
            <p className="quote">{quote}</p>
            <h2 className="name">{name}</h2>
            {twitter && <p className="twitter">{twitter}</p>}
            <p className="subtitle">{subText}</p>
        </div>
    );
};
