import React from 'react';
import { CardLeft } from './home-card/CardLeft';
import { CardRight } from './home-card/CardRight';

type Props = {};

export const HomeGrid: React.FC<Props> = ({}) => {
    return (
        <div className="home-tile-section">
            <div className="container">
                <div className="home-grid">
                    <CardLeft
                        titleText={'Be Seen.'}
                        blueText={'Get Recruited.'}
                        description={
                            'Recruiters check out profiles to see if the player is a good fit for their college. Create your profile and become viewable to hundreds of colleges.'
                        }
                        imgSrc={'prof-ex.jpg'}
                        altTxt={'Example of Stay Plugged In Player Profile'}
                        width={1920}
                        height={1080}
                        needsPadding={false}
                        border={false}
                        isRoute={false}
                    />
                    <CardRight
                        titleText={'Play In Events.'}
                        blueText={'Get Scouted.'}
                        description={
                            'From large LAN events to weekly online tournaments, there are endless opportunities to play in front of college scouts. Find an event and sign up to compete for cash prizes and scholarships today.'
                        }
                        buttonLabel={'Find Events'}
                        pageRoute={'events'}
                        imgSrc={'event-image-new.jpg'}
                        altTxt={'Stay Plugged In Esports Event'}
                        width={1920}
                        height={1080}
                        isRoute={true}
                    />
                    <CardLeft
                        titleText={'find'}
                        blueText={'college esports programs'}
                        description={
                            'Easily find and connect with colleges esport programs. Become the next star player in Collegiate Rocket League (CRL), Collegiate League of Legends (CLOL) and more.'
                        }
                        buttonLabel={'Find Colleges'}
                        pageRoute={'colleges'}
                        imgSrc={'platform-colleges.jpg'}
                        altTxt={'Find colleges with esports teams'}
                        width={1280}
                        height={720}
                        needsPadding={false}
                        border={false}
                        isRoute={true}
                    />
                    <CardRight
                        titleText={'Find Esports'}
                        blueText={'Scholarships'}
                        description={
                            'Beyond competitions, there are a number of opportunities to earn esports scholarships. Learn more about how you can receive scholarships through STEM competitions, essay writing and other opportunities.'
                        }
                        buttonLabel={'Scholarships'}
                        pageRoute={'scholarships'}
                        imgSrc={'recruit-example-new.jpg'}
                        altTxt={'Esports Recruit Commitment'}
                        width={1920}
                        height={1080}
                        isRoute={true}
                    />
                </div>
            </div>
        </div>
    );
};
