import Image from 'next/image';
import React from 'react';

type Props = {
    imageSrc: string;
    altText: string;
    hasOverlay?: boolean;
};

export const BackgroundImage: React.FC<Props> = ({ imageSrc, altText, hasOverlay }) => {
    return (
        <>
            <div className="background-wrapper">
                <div className="background-image">
                    {imageSrc && (
                        <Image
                            objectFit="cover"
                            layout="fill"
                            objectPosition="center"
                            src={imageSrc}
                            alt={altText}
                            placeholder={'blur'}
                            blurDataURL={imageSrc}
                        />
                    )}
                </div>
            </div>
            {hasOverlay && <div className="overlay" />}
        </>
    );
};
