import React from 'react';
import { SPINIcon } from '../../svg/SPINIcon';

type Props = {
    icon: JSX.Element;
    label: string;
    description: string;
};

export const FeatureCard: React.FC<Props> = ({ icon, label, description }) => {
    return (
        <div className="home-feature-card">
            <SPINIcon className="background-icon" />
            <div className="feature-icon">{icon}</div>
            <div className="feature-content">
                <h2 className="feature-label">{label}</h2>
                <p className="feature-description">{description}</p>
            </div>
        </div>
    );
};
