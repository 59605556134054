import { format } from 'date-fns';

import Link from 'next/link';
import React from 'react';
import { PostSummary } from '../../types/shared.types';
import { BackgroundImage } from '../backgrounds/FadedBackgroundImage';

type Props = {
    className?: string;
    article: PostSummary;
};

export const ArticleCard: React.FC<Props> = ({ className, article }) => {
    const image = article.leadingImage?.url;
    return (
        <Link href={`/news/${article.slug}`}>
            <a className={`article-card${className ? ` ${className}` : ''}`}>
                <BackgroundImage imageSrc={image} altText={`${article.title}`} hasOverlay={true} />
                <div className="article-content">
                    <h2 className="article-title">{article.title}</h2>
                    <p className="article-date">
                        {format(new Date(article.sys.firstPublishedAt), 'MMMM d, y')} -{' '}
                        {article.postAuthor?.name ?? 'CEH'}
                    </p>
                </div>
            </a>
        </Link>
    );
};
