import React from 'react';

interface Props {
    className?: string;
}

export const ProfileView: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? ` ${className}` : ''}`} width="110" height="65" viewBox="0 0 110 65">
            <g id="GUY" transform="translate(0.948 20.454)">
                <g id="Group_222" data-name="Group 222" className="cls-2" transform="translate(14 -8)">
                    <path
                        id="Path_94"
                        data-name="Path 94"
                        className="cls-1"
                        d="M16.361,19.279h.94a1.88,1.88,0,0,0,1.815-1.391,10.053,10.053,0,0,0,18.617,0,1.88,1.88,0,0,0,1.815,1.392h.94A1.881,1.881,0,0,0,42.369,17.4V11.107a1.881,1.881,0,0,0-1.881-1.881H39.6a11.377,11.377,0,0,0-22.342,0h-.892a1.881,1.881,0,0,0-1.881,1.881V17.4a1.881,1.881,0,0,0,1.881,1.881m12.063,2.27a7.459,7.459,0,1,1,7.459-7.459,7.459,7.459,0,0,1-7.459,7.459m0-18.617a8.45,8.45,0,0,1,7.364,4.318,10.047,10.047,0,0,0-14.2-.531q-.275.255-.531.531a8.45,8.45,0,0,1,7.365-4.318"
                        transform="translate(-10.507 0)"
                    />
                    <path
                        id="Path_95"
                        data-name="Path 95"
                        className="cls-1"
                        d="M28.9,96.664H6.935A6.935,6.935,0,0,0,0,103.6v8.092H35.834V103.6A6.935,6.935,0,0,0,28.9,96.664M33.24,109.1H2.594v-5.5a4.346,4.346,0,0,1,4.341-4.341H28.9a4.346,4.346,0,0,1,4.341,4.341Z"
                        transform="translate(0 -70.145)"
                    />
                </g>
                <g id="profilebox" transform="translate(-524.948 -1328.454)">
                    <g id="Path_92" data-name="Path 92" className="cls-3" transform="translate(524 1308)">
                        <path
                            className="cls-5"
                            d="M8,0h94a8,8,0,0,1,8,8V57a8,8,0,0,1-8,8H8a8,8,0,0,1-8-8V8A8,8,0,0,1,8,0Z"
                        />
                        <path
                            className="cls-6"
                            d="M 8 4 C 5.794403076171875 4 4 5.794399261474609 4 8 L 4 57 C 4 59.20560073852539 5.794403076171875 61 8 61 L 102 61 C 104.2055969238281 61 106 59.20560073852539 106 57 L 106 8 C 106 5.794399261474609 104.2055969238281 4 102 4 L 8 4 M 8 0 L 102 0 C 106.4182739257812 0 110 3.58172607421875 110 8 L 110 57 C 110 61.41827392578125 106.4182739257812 65 102 65 L 8 65 C 3.581710815429688 65 0 61.41827392578125 0 57 L 0 8 C 0 3.58172607421875 3.581710815429688 0 8 0 Z"
                        />
                    </g>
                    <g id="Group_17" data-name="Group 17" transform="translate(6.078 -6.795)">
                        <line
                            id="Line_1"
                            data-name="Line 1"
                            className="cls-4"
                            x2="29.829"
                            transform="translate(582.5 1330.5)"
                        />
                        <line
                            id="Line_2"
                            data-name="Line 2"
                            className="cls-4"
                            x2="29.829"
                            transform="translate(582.5 1338.897)"
                        />
                        <line
                            id="Line_3"
                            data-name="Line 3"
                            className="cls-4"
                            x2="29.829"
                            transform="translate(582.5 1347.295)"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};
