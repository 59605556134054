import { gql } from '@urql/core';
import { HomePageDocument, HomePageQuery, UpcomingEventsCardFragment } from 'generated/spin-graphql';
import { GetStaticProps } from 'next';
import { useMemo } from 'react';
import 'reflect-metadata';
import { UrqlSSGForPage } from 'server/utils/serverUtils/ssg.utils';
import { container } from 'tsyringe';
import { useQuery } from 'urql';
import { HomeLayout } from '../components/common/layout/HomeLayout';
import { SpinAppModals, useModals } from '../components/common/SpinModalProvider';
import { HomeGrid } from '../components/home/HomeGrid';
import { HomeHeader } from '../components/home/HomeHeader';
import { HomeJourney } from '../components/home/HomeJourney';
import { HomeReviews } from '../components/home/HomeReviews';
import { LatestNews } from '../components/home/LatestNews';
import { UpcomingEvents } from '../components/home/UpcomingEvents';
import { ContentfulService } from '../server/services/contentful.service';
import { filterDuplicates } from '../shared/shared.utils';
import { PostSummary } from '../types/shared.types';

gql`
    query HomePage {
        stickied_events: espin_event(
            where: { event_end_date: { _gte: "now()" }, status: { _eq: published }, stickied: { _is_null: false } }
            order_by: { stickied: asc }
        ) {
            ...UpcomingEvents
        }
        events: espin_event(
            limit: 5
            order_by: { event_start_date: asc }
            where: { event_end_date: { _gte: "now()" }, status: { _eq: published } }
        ) {
            ...UpcomingEvents
        }
    }
`;

export default function HomePageNew({ news }: { news?: Array<PostSummary> }) {
    const [result] = useQuery<HomePageQuery>({
        query: HomePageDocument,
        context: useMemo(() => ({ additionalTypenames: ['espin_event'] }), []),
    });
    let { events: all_events, stickied_events } = result.data ?? {};
    const events: UpcomingEventsCardFragment[] = useMemo(
        () => filterDuplicates([...(stickied_events ?? []), ...(all_events ?? [])], 'id'),
        [all_events, stickied_events],
    );

    const { openModal } = useModals();

    return (
        <HomeLayout>
            <HomeHeader
                ctaOnClick={() => {
                    openModal(SpinAppModals.SIGNUP_SELECT_ACCT_TYPE);
                }}
            />
            <HomeJourney />
            <HomeGrid />

            {/* this is not a problem the majority of time, however, if the user changes their primary game
            then returns here, the system re-fetches (since events has _technically_ changed for the user) */}
            {!!events && <UpcomingEvents events={events} />}
            <LatestNews news={news} />
            <HomeReviews />
        </HomeLayout>
    );
}

export const getStaticProps: GetStaticProps = async (ctx) => {
    const contentfulService = container.resolve(ContentfulService);
    const contentResponse = await contentfulService.getRecentPosts();
    const ssgResponse = await UrqlSSGForPage<HomePageQuery>(HomePageDocument);
    if (contentResponse) {
        return {
            props: {
                ...ssgResponse.props,
                news: contentResponse.postCollection.items,
            },
            revalidate: ssgResponse.revalidate,
        };
    }
    return ssgResponse;
};
