import React from 'react';
import { Testimonial } from '../journey/Testimonial';

type Props = {
    className?: string;
};

export const HomeReviews: React.FC<Props> = ({ className }) => {
    return (
        <div className="product-quotes home">
            <div className="container pricing">
                <h2 className="title">
                    {`Don't take it from us.`} {<br />} <span>{`Hear what our users have to say!`}</span>
                </h2>
                <div className="product-testimonials">
                    <Testimonial
                        quote={
                            'This time around it was a far less time consuming process and rather enjoyable with the tools SPIN has. I would go onto one website and all the information I spent hours looking for was in one spot and easy to find.'
                        }
                        name={'Jacob Mitcham'}
                        twitter="@CD0zer"
                        subText={"Committed to St. Edward's University"}
                    />
                    <Testimonial
                        quote={
                            "Stay Plugged In has been an incredible tool and resource for me to recruit new students to Ball State University. I've been blown away by the skill and quality of the students Stay Plugged In is able to connect us to as well as the open ear they have when it comes to feedback. Thrilled to continue working with them to build our program up!"
                        }
                        name={'Dan Marino'}
                        twitter="@bsuesports"
                        subText={'Director at Ball State University & Platform Member'}
                    />
                    <Testimonial
                        quote={
                            'If it was not for SPIN I would not be where I am today. As in, having so many esports connections, as well as playing competitive rocket league in college because I would have no idea where to begin looking to possibly compete for any of these schools.'
                        }
                        name={'Mark Spikula'}
                        twitter="@Magicc_RL"
                        subText={'Competing at West Virginia University'}
                    />
                    <Testimonial
                        quote={
                            "Very seamless and great process. I don't think it would've gone so smoothly without SPIN because you guys have the network to connect talent with colleges looking for new talent across America."
                        }
                        name={'Garrett Walker'}
                        twitter="@yaitsChiefu"
                        subText={'Competing at Concordia University, St. Paul'}
                    />
                </div>
            </div>
        </div>
    );
};
