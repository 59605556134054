import React from 'react';

interface Props {
    className?: string;
}

export const OpenQuoteIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? ` ${className}` : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path d="M5.4,8.5H9v10H0V8.8l3.6-7.3H9L5.4,8.5z" />
            <path d="M16.4,8.5H20v10h-9V8.8l3.6-7.3H20L16.4,8.5z" />
        </svg>
    );
};
