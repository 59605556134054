import Image from 'next/image';
import React from 'react';

type Props = {
    imageSrc: string;
    altText: string;
    hasOverlay?: boolean;
    width: number;
    height: number;
    needsPadding: boolean;
};

export const BackgroundImageHome: React.FC<Props> = ({
    imageSrc,
    altText,
    hasOverlay,
    width,
    height,
    needsPadding,
}) => {
    return (
        <>
            <div className={`background-wrapper${needsPadding ? ' needs-padding' : ''}`}>
                <div className={`background-image`}>
                    {imageSrc && (
                        <Image
                            objectFit="cover"
                            layout="intrinsic"
                            objectPosition="center"
                            src={imageSrc}
                            alt={altText}
                            width={width}
                            height={height}
                            placeholder={'blur'}
                            blurDataURL={imageSrc}
                        />
                    )}
                </div>
            </div>
            {hasOverlay && <div className="overlay" />}
        </>
    );
};
