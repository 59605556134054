import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { useAppState } from '../../../client/providers/AppProvider';
import { BackgroundImageHome } from '../../backgrounds/BackgroundImageHome';
import { BackgroundImage } from '../../backgrounds/FadedBackgroundImage';
import { SpinAppModals, useModals } from '../../common/SpinModalProvider';

type Props = {
    titleText: string;
    blueText: string;
    description: string;
    buttonLabel?: string;
    pageRoute?: string;
    imgSrc: string;
    altTxt: string;
    width: number;
    height: number;
    needsPadding?: boolean;
    isRoute: boolean;
};

export const CardRight: React.FC<Props> = ({
    titleText,
    blueText,
    description,
    buttonLabel,
    pageRoute,
    imgSrc,
    altTxt,
    width,
    height,
    needsPadding,
    isRoute,
}) => {
    const { user } = useAppState();
    const { openModal } = useModals();

    return (
        <div className="card-horizontal reverse">
            <div className="content-container right">
                <h2 className="title">
                    {`${titleText}`} <span>{`${blueText}`}</span>
                </h2>
                <p className="description">{`${description}`}</p>
                {isRoute ? (
                    <Link href={`/${pageRoute}`}>
                        <a className="button large-button blue-button button-right">{`${buttonLabel}`}</a>
                    </Link>
                ) : (
                    <button
                        onClick={() => {
                            openModal(SpinAppModals.SIGNUP_SELECT_ACCT_TYPE);
                        }}
                        className="button blue-button large-button"
                    >
                        Create Account
                    </button>
                )}
            </div>
            <div className="image-container">
                <BackgroundImageHome
                    imageSrc={`/images/home-images/${imgSrc}`}
                    altText={`${altTxt}`}
                    hasOverlay={false}
                    width={width}
                    height={height}
                    needsPadding={needsPadding}
                />
            </div>
        </div>
    );
};
